import React, { useEffect } from "react";

import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import configureStore from "./redux/configureStore.prod";
import { Auth } from "./methods/auth";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Forgotpassword from "./pages/Forgotpassword";
import Resetpassword from "./pages/Resetpassword";
import Profile from "./pages/Profile";
import Logs from "./pages/Logs"; 
import Settings from "./pages/Settings";
import ContentManagement from "./pages/ContentManagement";
import "./scss/main.scss";
import "react-pagination-js/dist/styles.css"; // import css
import ContactDetails from "./pages/Settings/ContactDetails"; 
import Users from "./pages/Users";
import AddEditUser from "./pages/Users/AddEditUser";
import UserDetail from "./pages/Users/Profiledetail";
import ResellerCategories from "./pages/ResellerCategories";
import ResellerCategoryDetail from "./pages/ResellerCategories/Detail";
import AddEditResellerCategory from "./pages/ResellerCategories/AddEdit"; 
import Roles from "./pages/Roles";
import AddEditRole from "./pages/Roles/AddEdit";
import AddEditContinent from "./pages/Continents/AddEditContinent";
import Continent from "./pages/Continents/index";
import Countriess from "./pages/Countries";
import AddEditCountry from "./pages/Countries/AddEditCountry";
import CountryDetail from "./pages/Countries/Detail";
import Cities from "./pages/CityPages";
import AddEditCity from "./pages/CityPages/AddEditCity";
import CityDetail from "./pages/CityPages/Detail";
import Plans from "./pages/Plans";
import AddEditPlan from "./pages/Plans/AddEdit";
import Features from "./pages/Features";
import AddEditFeature from "./pages/Features/AddEdit";
import Currency from "./pages/CurrencyPages";
import AddEditCurrency from "./pages/CurrencyPages/AddEditCurrency";
import CurrencyDetail from "./pages/CurrencyPages/Detail";
import Coupons from "./pages/Coupons";
import AddEditCoupon from "./pages/Coupons/AddEdit";
import EmailTemplate from "./pages/EmailTemplate";
import MerchantCustomer from "./pages/MerchantCustomer/MerchantCustomer";
import Details from "./pages/MerchantCustomer/Details";
import AddEditEmailTemplate from "./pages/EmailTemplate/AddEdit";
import Regions from "./pages/Regions";
import AddEditRegion from "./pages/Regions/AddEdit";
import BookingSystem from "./pages/BookingSystem";
import AddEditBookingSystem from "./pages/BookingSystem/AddEdit";
import Types from "./pages/categoriesType";
import AddEditTypes from "./pages/categoriesType/AddEdit";
import ApplyCurrency from "./pages/CurrencyPages/Applycurrencys";
import POS from "./pages/POS";
import AddEditPOS from "./pages/POS/AddEdit";
import Orders from "./pages/POS/Orders/Orders";
import Holiday from "./pages/Holiday";
import AddEditPrice from "./pages/DynamicPrice/AddEdit";
import DynamicPrice from "./pages/DynamicPrice";
import AddEditEarlyBirdPricing from "./pages/DynamicPrice/Early/AddEdit";
import AddEditEarlyBirdTimeFrames from "./pages/DynamicPrice/TimeFrames/AddEdit";
import AddEditEarlyBirdInventory from "./pages/DynamicPrice/Inventory/AddEdit";
import AddEditLastMinuteFixedDates from "./pages/DynamicPrice/LastMinuteFixedDates/AddEdit";
import AddEditLastMinuteInventory from "./pages/DynamicPrice/LastMinuteInventory/AddEdit";
import Customer from "./pages/Customers";
import ViewCustomer from "./pages/Customers/Profiledetail";
import Reseller from "./pages/ResellerPages";
import AddEditReseller from "./pages/ResellerPages/AddEditReseller";
import CRM from "./pages/CRM";
import AddEditCRM from "./pages/CRM/AddEdit";
import AddEditSkills from "./pages/SkillsPages/AddEditSkills";
import Skills from "./pages/SkillsPages";
import Industry from "./pages/Industry";
import AddEditIndustry from "./pages/Industry/AddEditIndustry";
import { ToastContainer } from "react-toastify";
import AddEditSubSkills from "./pages/SubSkillsPages/AddEditSubSkills";
import SubSkills from "./pages/SubSkillsPages";
import Faq from "./pages/Faq";
import AddEditFaq from "./pages/Faq/AddEditFaq";
import AddEditcategory from "./pages/category/AddEditcategory";
import CategoryList from "./pages/category";
import Categorydetail from "./pages/category/categorydetail";
import FaqDetail from "./pages/Faq/Detail";
import UpdateSettingsForm from "./pages/UpdateSettings";
import FeeSettings from "./pages/UpdateSettings/FeeSettings";
import { WebsiteDetail } from "./redux/actions/WebsiteDetail";
import TransactionVolume from "./pages/TransactionPages/TransactionVolume";
import TransactionValue from "./pages/TransactionPages/TransactionValue";
import Chart from "./pages/CommonChart/Chart";
import TransactionTable from "./pages/CommonChart/DumbyListing/Lisitng";
import Accounts from "./pages/accounts";
import AccountDetail from "./pages/accounts/accountdetails";
import Balance from "./pages/balance";
import AddCredential from "./pages/addCredential";
import AddEditCreds from "./pages/addCredential/addEditCreds";
import Funds from "./pages/funds/Funds";
import Adjustments from "./pages/funds/Adjustments";
import transactions from "./pages/Transactions";
import transactionDetail from "./pages/Transactions/transactiondetail";
import ContactUs from "./pages/contactus";
import AddEditMerchantCreds from "./pages/addMerchantCreds";
import ViewContactUs from "./pages/contactus/contactusdetail";
import Devices from "./pages/devices";
import AddEditDevice from "./pages/devices/addEditDevices";
import BlogsList from "./pages/BlogsList";
import AddEditBlog from "./pages/BlogsList/AddEditcategory";
import ViewBlog from "./pages/BlogsList/categorydetail";

// export const history = createBrowserHistory();
/************ store configration *********/
const { persistor, store } = configureStore();

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(WebsiteDetail());
  }, []);
  return (
    <>
      <PersistGate loading={"loading ..."} persistor={persistor}>
        <Router>
          <Routes>
            <Route
              exact={true}
              path="/dashboard"
              store={store}
              requireAuth={Auth}
              Component={Dashboard}
            />  
            <Route
              exact={true}
              path="/settings/appointment/contact-details"
              store={store}
              requireAuth={Auth}
              Component={ContactDetails}
            /> 
            <Route
              exact={true}
              path="/pos"
              store={store}
              requireAuth={Auth}
              Component={POS}
            />
            <Route
              exact={true}
              path="/pos/orders"
              store={store}
              requireAuth={Auth}
              Component={Orders}
            />
            <Route
              exact={true}
              path="/pos/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditPOS}
            />
            <Route
              exact={true}
              path="/pos/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditPOS}
            />
            <Route
              exact={true}
              path="/users"
              store={store}
              requireAuth={Auth}
              Component={Users}
            />
            <Route
              exact={true}
              path="/customer"
              store={store}
              requireAuth={Auth}
              Component={Customer}
            />
            <Route
              exact={true}
              path="/customer/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditUser}
            />
            <Route
              exact={true}
              path="/customer/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditUser}
            />
            <Route
              exact={true}
              path="/customer/view/:id"
              store={store}
              requireAuth={Auth}
              Component={ViewCustomer}
            />
            <Route
              exact={true}
              path="/account"
              store={store}
              requireAuth={Auth}
              Component={Accounts}
            />
            <Route
              exact={true}
              store={store}
              path="/accountDetail/:id"
              Component={AccountDetail}
            />
            <Route
              exact={true}
              store={store}
              path="/balance"
              Component={Balance}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/transaction"
              Component={transactions}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/transactionDetail/:id"
              Component={transactionDetail}
            />

            <Route
              exact={true}
              path="/industry"
              store={store}
              requireAuth={Auth}
              Component={Industry}
            />
            <Route
              exact={true}
              path="/industry/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditIndustry}
            />
            <Route
              exact={true}
              path="/industry/:role"
              store={store}
              requireAuth={Auth}
              Component={Industry}
            />
            <Route
              exact={true}
              path="/industry/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditIndustry}
            />
            <Route
              exact={true}
              path="/industry/:role/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditIndustry}
            />
            <Route
              exact={true}
              path="/industry/:role/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditIndustry}
            />

            <Route
              exact={true}
              path="/roles"
              store={store}
              requireAuth={Auth}
              Component={Roles}
            />
            <Route
              exact={true}
              path="/roles/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditRole}
            />
            <Route
              exact={true}
              path="/roles/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditRole}
            />
            <Route
              exact={true}
              path="/users/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditUser}
            />
            <Route
              exact={true}
              path="/users/:role"
              store={store}
              requireAuth={Auth}
              Component={Users}
            />
            <Route
              exact={true}
              path="/users/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditUser}
            />
            <Route
              exact={true}
              path="/users/:role/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditUser}
            />
            <Route
              exact={true}
              path="/users/:role/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditUser}
            />
            <Route
              exact={true}
              path="/users/userdetail/:id"
              store={store}
              requireAuth={Auth}
              Component={UserDetail}
            />
            <Route
              exact={true}
              path="/reseller-categories"
              store={store}
              requireAuth={Auth}
              Component={ResellerCategories}
            />
            <Route
              exact={true}
              path="/reseller-categories/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditResellerCategory}
            />
            <Route
              exact={true}
              path="/reseller-categories/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditResellerCategory}
            />
            <Route
              exact={true}
              path="/reseller-categories/detail/:id"
              store={store}
              requireAuth={Auth}
              Component={ResellerCategoryDetail}
            />
             
            <Route
              exact={true}
              path="/content"
              store={store}
              requireAuth={Auth}
              Component={ContentManagement}
            />
            <Route
              exact={true}
              path="/profile"
              store={store}
              requireAuth={Auth}
              Component={Profile}
            />
            <Route
              exact={true}
              path="/profile/:tab"
              store={store}
              requireAuth={Auth}
              Component={Settings}
            />
            <Route
              exact={true}
              path="/login"
              store={store}
              requireAuth={Auth}
              Component={Login}
            />
            <Route
              exact={true}
              path="/forgotpassword"
              store={store}
              requireAuth={Auth}
              Component={Forgotpassword}
            />
            <Route
              exact={true}
              path="/resetpassword"
              store={store}
              requireAuth={Auth}
              Component={Resetpassword}
            />
            {/* Categories Type */}
            <Route
              exact={true}
              path="/types"
              store={store}
              requireAuth={Auth}
              Component={Types}
            />
            <Route
              exact={true}
              path="/types/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditTypes}
            />
            <Route
              exact={true}
              path="/types/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditTypes}
            />
            {/* Feature */}
            <Route
              exact={true}
              path="/features"
              store={store}
              requireAuth={Auth}
              Component={Features}
            />
            <Route
              exact={true}
              path="/features/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditFeature}
            />
            <Route
              exact={true}
              path="/features/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditFeature}
            />
            {/* Continents */}
            <Route
              exact={true}
              path="/continents"
              store={store}
              requireAuth={Auth}
              Component={Continent}
            />
            <Route
              exact={true}
              path="/continents/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditContinent}
            />
            <Route
              exact={true}
              path="/continents/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditContinent}
            />
            {/* Plans */}
            <Route
              exact={true}
              path="/plans"
              store={store}
              requireAuth={Auth}
              Component={Plans}
            />
            <Route
              exact={true}
              path="/plans/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditPlan}
            />
            <Route
              exact={true}
              path="/plans/edit/:id/:copy"
              store={store}
              requireAuth={Auth}
              Component={AddEditPlan}
            />
            {/* Booking System */}
            <Route
              exact={true}
              path="/bookingSystem"
              store={store}
              requireAuth={Auth}
              Component={BookingSystem}
            />
            <Route
              exact={true}
              path="/bookingSystem/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditBookingSystem}
            />
            <Route
              exact={true}
              path="/bookingSystem/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditBookingSystem}
            />
            {/* Coupon */}
            <Route
              exact={true}
              path="/coupon"
              store={store}
              requireAuth={Auth}
              Component={Coupons}
            />
            <Route
              exact={true}
              path="/coupon/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditCoupon}
            />
            <Route
              exact={true}
              path="/coupon/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditCoupon}
            />
            {/* Country */}
            <Route
              exact={true}
              path="/countries"
              store={store}
              requireAuth={Auth}
              Component={Countriess}
            />
            <Route
              exact={true}
              path="/countries/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditCountry}
            />
            <Route
              exact={true}
              path="/countries/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditCountry}
            />
            <Route
              exact={true}
              path="/countries/:id"
              store={store}
              requireAuth={Auth}
              Component={CountryDetail}
            />
            {/* Region */}
            <Route
              exact={true}
              path="/regions"
              store={store}
              requireAuth={Auth}
              Component={Regions}
            />
            <Route
              exact={true}
              path="/regions/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditRegion}
            />
            <Route
              exact={true}
              path="/regions/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditRegion}
            />
            {/* City */}
            <Route
              exact={true}
              path="/cities"
              store={store}
              requireAuth={Auth}
              Component={Cities}
            />
            <Route
              exact={true}
              path="/cities/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditCity}
            />
            <Route
              exact={true}
              path="/cities/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditCity}
            />
            <Route
              exact={true}
              path="/cities/:id"
              store={store}
              requireAuth={Auth}
              Component={CityDetail}
            />
            {/* Currency */}
            <Route
              exact={true}
              path="/currency"
              store={store}
              requireAuth={Auth}
              Component={Currency}
            />
            <Route
              exact={true}
              path="/currency/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditCurrency}
            />
            <Route
              exact={true}
              path="/currency/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditCurrency}
            />
            <Route
              exact={true}
              path="/currency/:id"
              store={store}
              requireAuth={Auth}
              Component={CurrencyDetail}
            />
            {/* Apply Currency */}
            <Route
              exact={true}
              path="/applycurrency"
              store={store}
              requireAuth={Auth}
              Component={ApplyCurrency}
            />
            {/* Holiday */}
            <Route
              exact={true}
              path="/holiday"
              store={store}
              requireAuth={Auth}
              Component={Holiday}
            />
            {/* Last Minute Pricing */}
            <Route
              exact={true}
              path="/dynamicprice/lastminutefixeddates/add"
              requireAuth={Auth}
              Component={AddEditLastMinuteFixedDates}
            />
            <Route
              exact={true}
              path="/dynamicprice/lastminutefixeddates/edit/:id/:copy"
              requireAuth={Auth}
              Component={AddEditLastMinuteFixedDates}
            />
            <Route
              exact={true}
              path="/dynamicprice/lastminuteinventory/add"
              requireAuth={Auth}
              Component={AddEditLastMinuteInventory}
            />
            <Route
              exact={true}
              path="/dynamicprice/lastminuteinventory/edit/:id/:copy"
              requireAuth={Auth}
              Component={AddEditLastMinuteInventory}
            />
            {/* Early Bird Pricing */}
            <Route
              exact={true}
              path="/dynamicprice/earlybirdcustomdates/add"
              requireAuth={Auth}
              Component={AddEditEarlyBirdPricing}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdcustomdates/edit/:id/:copy"
              requireAuth={Auth}
              Component={AddEditEarlyBirdPricing}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdtimeframes/add"
              requireAuth={Auth}
              Component={AddEditEarlyBirdTimeFrames}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdtimeframes/edit/:id/:copy"
              requireAuth={Auth}
              Component={AddEditEarlyBirdTimeFrames}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdinventory/add"
              requireAuth={Auth}
              Component={AddEditEarlyBirdInventory}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdinventory/edit/:id/:copy"
              requireAuth={Auth}
              Component={AddEditEarlyBirdInventory}
            />
            {/* Dynamic Price */}
            <Route
              exact={true}
              path="/dynamicpricelist"
              store={store}
              requireAuth={Auth}
              Component={DynamicPrice}
            />
            <Route
              exact={true}
              path="/dynamicprice/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditPrice}
            />
            <Route
              exact={true}
              path="/dynamicprice/:type"
              store={store}
              requireAuth={Auth}
              Component={DynamicPrice}
            />
            <Route
              exact={true}
              path="/dynamicprice/:type/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditPrice}
            />
            <Route
              exact={true}
              path="/dynamicprice/:type/edit/:id/:copy"
              store={store}
              requireAuth={Auth}
              Component={AddEditPrice}
            />

            <Route
              exact={true}
              path="/crm"
              store={store}
              requireAuth={Auth}
              Component={CRM}
            />
            <Route
              exact={true}
              path="/crm/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditCRM}
            />
            <Route
              exact={true}
              path="/crm/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditCRM}
            />

            {/* Email Template */}
            <Route
              exact={true}
              path="/emailtemplate"
              store={store}
              requireAuth={Auth}
              Component={EmailTemplate}
            />
            <Route
              exact={true}
              path="/emailtemplate/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditEmailTemplate}
            />
            <Route
              exact={true}
              path="/emailtemplate/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditEmailTemplate}
            />

            {/*  For Reseller  */}
            <Route
              exact={true}
              path="/reseller"
              store={store}
              requireAuth={Auth}
              Component={Reseller}
            />
            <Route
              exact={true}
              path="/reseller/addedit/:id?"
              store={store}
              requireAuth={Auth}
              Component={AddEditReseller}
            />
            {/*  Routes for Skills  */}
            <Route
              exact={true}
              path="/addeditskill/:id?"
              requireAuth={Auth}
              store={store}
              Component={AddEditSkills}
            />
            <Route
              exact={true}
              path="/skills"
              requireAuth={Auth}
              store={store}
              Component={Skills}
            />
         
            <Route
              exact={true}
              path="/masterskils"
              requireAuth={Auth}
              store={store}
              Component={SubSkills}
            />
            <Route
              exact={true}
              path="/addeditmasterskils/:id?"
              requireAuth={Auth}
              store={store}
              Component={AddEditSubSkills}
            />

            <Route
              exact={true}
              path="/faq"
              store={store}
              requireAuth={Auth}
              Component={Faq}
            />
            <Route
              exact={true}
              path="/AddEditfaq"
              requireAuth={Auth}
              store={store}
              Component={AddEditFaq}
            />
            <Route
              exact={true}
              path="/AddEditfaq/:id?"
              requireAuth={Auth}
              store={store}
              Component={AddEditFaq}
            />
            <Route
              exact={true}
              path="/faqDetails/:id?"
              requireAuth={Auth}
              store={store}
              Component={FaqDetail}
            />

            <Route
              exact={true}
              path="/logs"
              store={store}
              requireAuth={Auth}
              Component={Logs}
            />

            <Route
              exact={true}
              path="/categorylist"
              store={store}
              requireAuth={Auth}
              Component={CategoryList}
            />
            <Route
              exact={true}
              path="/AddEditcategory"
              requireAuth={Auth}
              store={store}
              Component={AddEditcategory}
            />
            <Route
              exact={true}
              path="/AddEditcategory/:id?"
              requireAuth={Auth}
              store={store}
              Component={AddEditcategory}
            />
            <Route
              exact={true}
              path="/categorydetail/:id?"
              requireAuth={Auth}
              store={store}
              Component={Categorydetail}
            />

<Route
              exact={true}
              path="/blogs"
              store={store}
              requireAuth={Auth}
              Component={BlogsList}
            />

<Route
              exact={true}
              path="/blogs/add"
              store={store}
              requireAuth={Auth}
              Component={AddEditBlog}
            />

<Route
              exact={true}
              path="/blogs/edit/:id"
              store={store}
              requireAuth={Auth}
              Component={AddEditBlog}
            />

<Route
              exact={true}
              path="/blogs/view/:id"
              store={store}
              requireAuth={Auth}
              Component={ViewBlog}
            />

            <Route
              exact={true}
              path="/website/settings"
              requireAuth={Auth}
              store={store}
              Component={UpdateSettingsForm}
            />
            <Route
              exact={true}
              path="/fee/settings"
              requireAuth={Auth}
              store={store}
              Component={FeeSettings}
            />
            <Route
              exact={true}
              path="/transaction/volume"
              requireAuth={Auth}
              store={store}
              Component={TransactionVolume}
            />
            <Route
              exact={true}
              path="/transaction/value"
              requireAuth={Auth}
              store={store}
              Component={TransactionValue}
            />
            <Route
              exact={true}
              path="/details/:id"
              requireAuth={Auth}
              store={store}
              Component={Details}
            />
            <Route
              exact={true}
              path="/merchantCustomer"
              requireAuth={Auth}
              store={store}
              Component={MerchantCustomer}
            />
            <Route
              exact={true}
              path="/chart/:slug"
              requireAuth={Auth}
              store={store}
              Component={Chart}
            />
            <Route
              exact={true}
              path="/transactions"
              requireAuth={Auth}
              store={store}
              Component={TransactionTable}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/addcredential"
              Component={AddCredential}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/addeditcreds"
              Component={AddEditCreds}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/addeditcreds/edit/:id"
              Component={AddEditCreds}
            />
              <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/AddEditMerchantCreds/:marchid"
              Component={AddEditMerchantCreds}
            />
              <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/AddEditMerchantCreds/:marchid/:id"
              Component={AddEditMerchantCreds}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/funds"
              Component={Funds}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/contactus"
              Component={ContactUs}
            />
               <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/contactusdetail/:id"
              Component={ViewContactUs}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/adjustments"
              Component={Adjustments}
            />
             <Route
              exact={true}
              path="/devices"
              store={store}
              requireAuth={Auth}
              Component={Devices}
            />
               <Route
              exact={true}
              path="/addeditdevices"
              store={store}
              requireAuth={Auth}
              Component={AddEditDevice}
            />

            <Route path="/" element={<Navigate to="/login" replace />} />
          </Routes>
        </Router>
      </PersistGate>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
