import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import { Link } from "react-router-dom";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";

const Html = ({
  tab,
  edit,
  form,
  setform,
  ChangeStatus,
  ChangeRole,
  ShowActiveModal,
  setShowActiveModal,
  ChangeCatType,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  loaging,
  ShowDeleteModal,
  setShowDeleteModal,
  data,
  clear,
  total,
}) => {
  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };
  const [StatusData, setStatusData] = useState({});
  const ConfirmFunction = () => {
    statusChange(StatusData);
  };

  const TableRow = ({ itm, className, index, parentCategory }) => {
    return (
      <tr>
        <td className="table_dats table-bx">{itm.title || "--"}</td>
        <td className="table_dats table-bx">{itm.short_description || "--"}</td>
        <td className="table_dats table-bx">
          {" "}
          <div className={` `}>
            {itm.status == "deactive" ? (
              <Switch
                onChange={(e) => {
                  setStatusData(itm);
                  setShowActiveModal("block");
                }}
                checked={false}
              />
            ) : (
              <Switch
                onChange={(e) => {
                  setStatusData(itm);
                  setShowActiveModal("block");
                }}
                checked={true}
              />
            )}
          </div>
        </td>
        <td className="table_dats table-bx">
          <div className="action_icons">
            {/* {isAllow(type?'editResellerCategory':'editCategory')? */}
            <a className="edit_icon" title="Edit" onClick={(e) => edit(itm.id)}>
              <i class="material-icons edit" title="Edit">
                edit
              </i>
            </a>
            {/* :<></>} */}
            {/* {isAllow(type?'deleteResellerCategory':'deleteCategory')? */}
            <span
              className="edit_icon"
              onClick={() => {
                setDeleteId(itm.id);
                setShowDeleteModal("block");
              }}
            >
              <i class="material-icons delete" title="Delete">
                {" "}
                delete
              </i>
            </span>
            {/* :<></>} */}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Layout>
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={ConfirmFunction}
        status={StatusData.status}
      />
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Blogs</h3>

        <article className="d-flex justify-content-between catbtn mb-3 gap-2">
          {/* {isAllow('addSkillType') ? */}
          <Link className="btn btn-primary" to="/blogs/add">
            Add Blog
          </Link>
          {/* : <></>} */}

          <div className="dropdown addDropdown">
            <button
              className="btn btn-primary dropdown-toggle removeBg"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filters.status
                ? filters.status == "deactive"
                  ? "Inactive"
                  : filters.status
                : "All Status"}
            </button>
            <div
              className="dropdown-menu shadow bg_hover"
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className={
                  filters.status == ""
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("")}
              >
                All Status
              </a>
              <a
                className={
                  filters.status == "active"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("active")}
              >
                Active
              </a>
              <a
                className={
                  filters.status == "Inactive"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("deactive")}
              >
                Inactive
              </a>
            </div>
          </div>


          {filters.status||filters.search?<>
            <button className="btn btn-secondary" type="button" onClick={clear}>Reset</button>
          </>:<></>}


          {/* <div className="">
            <select
              className="form-control ccttype ml-2"
              required
              value={form?.catType}
              onChange={(e) => ChangeCatType(e.target.value)}
            >
              <option value="">Filters</option>
              <option className="text-capitalize" value="page">
                Page
              </option>
              <option className="text-capitalize" value="Faq">
                Faq
              </option>
              <option className="text-capitalize" value="products">
                Products
              </option>
            </select>
          </div> */}
        </article>
      </div>

      {tab == "grid" ? (
        <></>
      ) : (
        <>
          <div className="table table-responsive">
            <div className="table_section">
              <table class="table table-striped table-hover">
                <thead className="table_head">
                  <tr className="heading_row">
                    <th scope="col" className="table_data">
                      Title
                    </th>
                    <th scope="col" className="table_data">
                      Short Description
                    </th>
                    <th scope="col" className="table_data">
                      Status
                    </th>
                    <th scope="col" className="table_data">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody id="tableBodycat">
                  {data &&
                    data.map((itm, i) => {
                      return (
                        <>
                          <TableRow itm={itm} index={i} />
                          {itm?.childCategories &&
                            itm?.childCategories.map((citm) => {
                              return (
                                <>
                                  <TableRow
                                    itm={{ ...citm, id: citm._id }}
                                    index={i}
                                    className="subCategory"
                                    parentCategory={itm}
                                  />
                                  {citm.childCategories &&
                                    citm.childCategories.map((sitm) => {
                                      return (
                                        <>
                                          <TableRow
                                            itm={{ ...sitm, id: citm._id }}
                                            index={i}
                                            className="subSubCategory"
                                            parentCategory={citm}
                                          />
                                        </>
                                      );
                                    })}
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {!loaging && total == 0 ? (
        <div className="py-3 text-center">No Data</div>
      ) : (
        <></>
      )}

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} Blogs
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
