import React, { useEffect, useState } from "react";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../components/global/layout";
import { useSelector } from "react-redux";
import ImageUpload from "../../../components/common/ImageUpload";
import methodModel from "../../../methods/methods";
import { Editor } from "@tinymce/tinymce-react";
// import methodModel from "../../methods/methods";

export default function AddEditcategory() {
  const [images, setImages] = useState({ image: ''});
  const [tag, setTag] = useState('');
  const { id, type } = useParams();
  const [form, setform] = useState({ 
    id: "",
    title: "",
    metaTitle: "",
    short_description: "",
    meta_keywords: [],
    meta_description: "",
    description: "",
   });
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const formValidation = [
    { key: "title", required: true },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);

    if (invalid) return;
    let method = "post";
    let url = "blog/add";
    let value = {
      ...form,
      ...images,
    };
    if (value.id) {
      method = "put";
      url = "update-blog";
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history("/blogs");
      }
      loader(false);
    });
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
    setform({ ...form, image: e.value }); 
  };


  const back = () => {
    history(-1);
  };

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get("blog-detail", { id }).then((res) => {
        if (res.success) {
          let value = res.payload;
          let payload = form;
          let oarr = Object.keys(payload);
          oarr.map((itm) => {
            payload[itm] = value[itm] || "";
          });
          setform({
            ...payload,
          });
          Object.keys(images).map((itm) => {
            images[itm] = value?.[itm];
          });
          setImages(images);
        }
        loader(false);
      });
    }
  }, [id]);

  const addTag=()=>{
    if(tag){
      let arr=form.meta_keywords||[]
      arr.push(tag)
      setTag('')
      setform(prev=>({...prev,meta_keywords:arr}))
    }
  }

  const removeTag=(i)=>{
    let arr=form.meta_keywords||[]
    arr=arr.filter((itm,ind)=>ind!=i)
    setform(prev=>({...prev,meta_keywords:arr}))
  }

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
        <h3 className="ViewUser mb-3">
              {form && form.id ? "Edit" : "Add"} {type ? "Reseller" : ""}{" "}
              Blog
            </h3>
          <div className="pprofile1 edit_profile">
          

            <div className="form-row">

            <div className="col-md-6 mb-3">
                    <label>
                      Title<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.title}
                      onChange={(e) =>
                        setform(prev=>({ ...prev, title: e.target.value }))
                      }
                      required
                    />
                  </div>

                 

                  <div className="col-md-12 mb-3">
                    <label>
                      Short Description<span className="star">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      value={form.short_description}
                      onChange={(e) =>
                        setform(prev=>({ ...prev, short_description: e.target.value }))
                      }
                      required
                    />
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>
                      Description<span className="star">*</span>
                    </label>
                    {/* <textarea
                      className="form-control"
                      value={form.description}
                      onChange={(e) =>
                        setform(prev=>({ ...prev, description: e.target.value }))
                      }
                      required
                    /> */}
                    <Editor apiKey='e9b46x5ebse3zswyqxc5gpl8b5zzduu2ziq9r75c2s91ytpe'
                                                         value={form?.description||''}
                                                          className='tuncketcls'
                                                            onEditorChange={(newValue, editor) => {
                                                                setform({ ...form, description: newValue })
                                                            }}
                                                            required
                                                        />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>
                      Meta Title
                      {/* <span className="star">*</span> */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.metaTitle}
                      onChange={(e) =>
                        setform(prev=>({ ...prev, metaTitle: e.target.value }))
                      }
                      // required
                    />
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>
                      Meta Description
                      {/* <span className="star">*</span> */}
                    </label>
                    <textarea
                      className="form-control"
                      value={form.meta_description}
                      onChange={(e) =>
                        setform(prev=>({ ...prev, meta_description: e.target.value }))
                      }
                      // required
                    />
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>
                      Keywords
                      {/* <span className="star">*</span> */}
                    </label>

                    <div className="d-flex gap-2">
                      <input
                      type="text"
                      className="form-control"
                      value={tag}
                      onChange={(e) =>
                        setTag(e.target.value)
                      }
                      // required
                    />
                    <button className="btn btn-primary" type="button" onClick={addTag}>Add</button>
                    </div>
                    
                    <div className="d-flex flex-wrap gap-2 mt-2">
                      {form.meta_keywords.map((itm,i)=>{
                        return  <span className="badge badge-primary">
                          {itm}
                        <i className="fa fa-times ml-2" onClick={()=>removeTag(i)}></i>
                      </span>
                      })}
                    </div>
                    {/* <textarea
                      className="form-control"
                      value={form.meta_description}
                      onChange={(e) =>
                        setform(prev=>({ ...prev, meta_description: e.target.value }))
                      }
                      // required
                    /> */}
                  </div>
                      
                  

                  <div className="col-md-6  mb-3">
                    <label className="lablefontcls">Image</label>
                    <br></br>
                    <ImageUpload
                      model="users"
                      result={(e) => imageResult(e, "image")}
                      value={images.image || form.image}
                    />
                  </div>
            </div>
            <div className="text-right">
              <button
                type="button"
                className="btn btn-secondary mr-2 discard"
                onClick={(e) => back()}
              >
                Back
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
}
